<template>
  <div class="quoteBoxPage">
    <MainHeader />
    <CommonTop />
    <div class="content">
      <!--快速报价 begin-->
      <div class="quot" id="one">
        <div class="quotbox">
          <form id="form" enctype="multipart/form-data" action="/index.php/Home/Order/kbaojia_step2.html" type="post">
            <input type="hidden" name="goodLength" value="3">

            <!-- 添加导航 -->

            <Nav :height="'482px'" :isShow="false" style="width: 1200px;margin: 0 auto;" />


            <div id="top_info">
              <div class="ptitle" style="border-left:4px solid #D9262C ;">公司信息</div>

              <div class="com-info">
                <div class="com-list">
                  <span style="background-color: #ececec;">制单人</span>
                  <p>{{ userInfo.name }}</p>
                </div>
                <div class="com-list">
                  <span style="background-color: #ececec;">公司名称</span>
                  <p>{{ userInfo.company_name }}</p>
                </div>
              </div>
            </div>
            <template v-if="showQoute">
              <div class="ptitle" style="border-left:4px solid #D9262C">商品明细</div>
              <div class=" table-box">
                <table id="good-info">
                  <tbody>
                    <tr style="border: 0px;">
                      <th width="70">
                        <!-- <input type="checkbox" class="checkAll" onclick="selectall(this.checked)"> -->
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
                          :style="checkAll ? 'color: #D9262C;' : ''">全选</el-checkbox>
                      </th>
                      <th width="100">编号</th>
                      <!-- <th width="200">产品名称</th> -->
                      <th width="309">规格型号</th>
                      <th width="410">备注</th>
                      <th width="129">数量</th>
                    </tr>
                    <tr class="selectGood" v-for="(item, index) in production" :key="item.key + '-' + index">
                      <td width="70">
                        <!-- <input name="ids" type="checkbox" onclick="selectone()" value="1"> -->
                        <el-checkbox @change="selectone(index)" :value="selectIndexs.indexOf(index) > -1"></el-checkbox>
                      </td>
                      <!-- <td width="100"><span>{{item.id}}</span><input type="hidden"></td> -->
                      <td width="100"><span>{{ index + 1 }}</span><input type="hidden"></td>
                      <!-- <td width="200"><input type="text" placeholder="请输入产品名称" v-model="item.title"></td> -->
                      <td width="309">
                        <input type="text" placeholder="请输入产品规格型号" v-model="item.production_model">
                      </td>
                      <td width="410"><input type="text" maxlength="50" placeholder="请输入备注" v-model="item.remark"></td>
                      <td width="129" style="border:0px"><input type="text" v-model="item.number"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="conbox">
                <!-- <select @change="selectClass($event)">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select> -->
                <a class="btn-gray" @click="addNew">添加行</a>
                <a href="javascript:;" class="control-excel" style="float:right;" @click="show = true">
                  <img src="@/assets/images/fs/img1.png" alt="">Excel批量导入
                </a>
                <a href="javascript:;" @click="del">
                  <img src="@/assets/images/fs/img2.png" style="float: right;margin-top: 4px; margin-right: 18px;" alt="">
                </a>
              </div>
              <div>
                <a v-loading="nextLoading" class="nexta" @click="nextStep">下一步</a>
              </div>
            </template>
            <div class="clear"></div>
            <div class="projectbox" v-if="show">
              <div class="project">
                <div class="protitle">
                  <span class="fl">excel批量导入</span>
                  <a @click="show = false">x</a>
                </div>
                <div class="pro-dl">
                  <div style="width:625px;margin:20px auto;">
                    <p>
                      <span class="f16 fl">模板格式：</span>
                      <a href="https://penglong-shop.oss-cn-beijing.aliyuncs.com/default/2023-09-27/%E9%B9%8F%E9%9A%86%E6%89%B9%E9%87%8F%E6%8A%A5%E4%BB%B7%E6%A8%A1%E6%9D%BF.xlsx"
                        class="cfont" target="_blank">
                        <i class="iconfont"></i>&nbsp;模板下载
                      </a>
                    </p>
                  </div>
                </div>
                <div class="dropzone dz-clickable">
                  <el-upload class="upload-demo" :action="action" :headers="headers" drag :multiple="true"
                    :before-upload="beforeUpload" :on-success="uploadSuccess" name="excel">
                    <div class="el-upload__text">拖动文件至此或者点击上传<br>单次可上传多个文件</div>
                  </el-upload>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="shadow" v-if="show"></div>
    <div class="qoue-detail-box" v-if="!showQoute">
      <div class="ptitle">商品明细</div>
      <qTable :tableData="qouteData" @click="operation"></qTable>
      <div class="btn-box">
        <!-- <el-button @click="backUpStep" style="border: 1px solid  #f1d6d6; background-color: #f3e9e9;"
          >返回</el-button> -->
        <button class="btn1" @click="backUpStep">返回</button>

        <!-- <el-button @click="submit" type="primary">提交</el-button> -->
        <button @click="submit" class="btn2">提交</button>
      </div>
    </div>
    <form-dialog :formData="formData" :visible.sync="showEditor" @confim="dialogConfim"
      :loading="dialogBtnLoading"></form-dialog>
  </div>
</template>

<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
import Nav from '@/components/nav/index.vue'
import { quotationConfirm, quotation } from '@/api/qoute.js'
import qTable from './table.vue'
import formDialog from '@/components/formDialog/index.vue'
import { mapState } from 'vuex'
export default {
  components: {
    MainHeader,
    CommonTop,
    qTable,
    formDialog,
    Nav
  },
  data() {
    return {
      show: this.$route.params.upload === 'upload',
      production: [],
      leng: 1,
      action: '',
      dataFile: {},
      isIndeterminate: false,
      checkAll: false,
      selectIndexs: [],
      headers: {
        Authorization: ''
      },
      showQoute: true,
      qouteData: [
      ],//报价详情
      showEditor: false,
      formData: {},//用于修改报价
      editorIndex: -1, //编辑的第几条数据
      dialogBtnLoading: true,
			nextLoading: false,
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  mounted() {
    console.log(this.$route.params.upload, "::::router:::::")
    this.action = process.env.VUE_APP_BASEURL + '/api/quotation/import-excel';

    this.addNew()
    this.leng = 1;
    const token = localStorage.getItem('token')
    if (token) {
      this.headers.Authorization = 'Bearer ' + token
    }
  },
  methods: {

    addNew() {
      var arr = [];
      let key = Math.random() * 1000;
      key = Math.floor(key)
      for (var i = 0; i < this.leng; i++) {
        var obj = { key: key + 1, production_model: '', remark: '', number: 1 }
        arr.push(obj);
      }
      this.production = [...this.production, ...arr];
      console.log(this.$route.query.production_model)
      if (this.$route.query.production_model) {
        this.production[0].production_model = this.$route.query.production_model
        if (this.$route.query.num) {
          this.production[0].number = this.$route.query.num
        }
      }
    },
    selectClass(event) {
      this.leng = event.target.value;
    },
    beforeUpload(file) {
      this.dataFile = {
        excel: file
      }
      console.log(file)
    },
    handleCheckAllChange(v) {
      // this.isIndeterminate = v
      if (this.isIndeterminate || !v) {
        this.isIndeterminate = false
        this.checkAll = false
        this.selectIndexs = []
      } else {
        this.selectIndexs = [...new Array(this.production.length).keys()]
        console.log('9999', this.selectIndexs)
      }
      console.log(v, "::::VVVV", this.isIndeterminate)
    },

    selectone(index) {
      let checked = event.target.checked
      if (!checked) {
        let delIndex = this.selectIndexs.indexOf(index)
        this.selectIndexs.splice(delIndex, 1)
        this.isIndeterminate = this.selectIndexs.length !== 0
        this.checkAll = false
        console.log('pppp', this.isIndeterminate, this.checkAll, this.selectIndexs)
        return false

      }
      // 选中
      this.selectIndexs.push(index)
      if (this.selectIndexs.length === this.production.length && this.selectIndexs.length !== 0) {
        this.isIndeterminate = false
        this.checkAll = true
      } else if (this.selectIndexs.length > 0) {
        this.isIndeterminate = true
        this.checkAll = false
      }
      console.log(index, this.selectIndexs)
    },
    // 删除按钮
    del() {
      let indexs = this.selectIndexs.slice()
      console.log(indexs, '----', this.selectIndexs)
      // 没有勾选 直接return
      if (!indexs.length) return false
      let production = this.production.slice()
      let newProduction = []
      for (let i = 0, len = production.length; i < len; i++) {
        if (indexs.indexOf(i) === -1) {
          newProduction.push(production[i])
        }
      }
      this.selectIndexs = []
      this.production = newProduction
      this.isIndeterminate = false
      this.checkAll = false
      console.log('00000')
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (!res.length) return false
      res.forEach(i => i.production_model = String(i.production_model));
      let newProduction = []
      let production = this.production.slice()
      for (let i = 0, len = production.length; i < len; i++) {
        let obj = production[i]
        if (obj.production_model) {
          newProduction.push(obj)
        }
      }
      this.production = [...newProduction, ...res]
      this.$message.success(`导入成功！`);
      this.show = false
    },
    // 下一步
    nextStep() {
      let production = this.production
      let len = production.length
      if (!len) {
        this.$message.error(`请输入您的报价信息`);
        return false
      }
      let isFlag = true
      for (let i = 0; i < len; i++) {
        let obj = production[i]
        if (!obj.production_model) {
          this.$message.error(`请输入第${i + 1}条的规格型号`);
          isFlag = false
          break
        }
        obj.quantity = obj.number
      }
      if (!isFlag) return false
			this.nextLoading = true;
			const task = (item) => {
				return new Promise((resove, reject) => {
					item.is_exist = 2;
					item.goods_discount = [];
					if (item.goods_id == 0) {
						resove(item)
					} else {
						this.$http.post('/api/goods/selection', {
							goods_id: item.goods_id,
							production_model: item.production_model
						}).then((data) => {
							console.log(data.pro_selection2)
							data.pro_selection2 = data.pro_selection2.filter((item) => item.var_type == 1 || item.var_type == 2 || item.param_type == 3)
							data.pro_code = data.pro_code.filter((item) => {
								return data.pro_selection2.some((j) => j.goods_param_id == item.id)
							})
							data.pro_selection2 = data.pro_selection2.filter((item) => {
								return data.pro_code.some((j) => j.id == item.goods_param_id)
							})
							data.pro_selection2.sort((a, b) => a.sort - b.sort)
							data.optional_processing = data.optional_processing.filter(j => j.var_type == 3 && (j.param_type == 2 || j.param_type == 4))




							const money = this.priceSort(data.pro_selection2, data.optional_processing, data.pro_code, item.production_model)

							this.$http.get(`/api/goods/${item.goods_id}`).then(({ goods_discount }) => {

								item.goods_discount = goods_discount
								if (money) {
									const price = money
									const tax_price = this.getBit(this.accMul(money, 1.13))
									const total_price = this.accMul(tax_price, item.quantity)
									let discountPrice = total_price;
									let price1 = price;
									let tax_price1 = tax_price;
									let flag=true;
									for (let i = 0; i < goods_discount.length; i++) {
										if (goods_discount[i].min <= item.quantity && goods_discount[i].max >= item.quantity) {
											price1 = this.getBit(this.accMul(price1, goods_discount[i].discount))
											tax_price1 = this.getBit(this.accMul(tax_price1, goods_discount[i].discount))
											discountPrice = this.getBit(this.accMul(total_price, goods_discount[i].discount))
											flag=false;
											break;
										}
									}
									if(flag){
										item.is_exist = 2;
										resove(Object.assign({}, item,{price1:""}))
									}else{
										item.is_exist = 1;
										resove(Object.assign({}, item, { price, tax_price,price1,tax_price1, total_price, discountPrice }))
									}

								} else {
									resove(Object.assign({}, item,{price1:""}))
								}
							});

							//price: this.priceData.price, tax_price: this.priceData.tax_price, total_price: this.priceData.total_price

						})
					}
				})
			}
      quotationConfirm({ production: production }).then(async (data1) => {
        let onceData = data1 || [];
				const tableList = [];
				try{
					for(let i = 0; i < onceData.length; i++) {
						const res = await task(onceData[i]);
						tableList.push(res);
					}
				}catch(e){
					console.log(e);
				}
				this.qouteData.splice(0, this.qouteData.length, ...tableList)
				this.showQoute = false
      }).catch(e => {
				this.nextLoading = false;
			})
    },
    dealTZSF(str, colName, colNameVal, rowName) {
      colNameVal=colNameVal.map(item=>isNaN(item)?item:parseFloat(item))
      if (str.split("\n").length > 1 && !str.endsWith("\n")) {
        str += "\n"
      }
      colName.map((item, index) => {
        window[item] = colNameVal[index]
      })

      const newstr = str.replaceAll("THEN", "")
        .replaceAll(".AND.", " && ")
        .replaceAll(".OR.", " || ")
        .replaceAll(".EQ.", " == ")
        .replaceAll(".GE.", " >= ")
        .replaceAll(".LE.", " <= ")
        .replaceAll(".GT.", " > ")
        .replaceAll(".LT.", " < ")
        .replaceAll(".NE.", " != ")
        .replaceAll("ELSEIF", "if")
        .replace("ENDIF", "")
        .replaceAll("IF", "if")
        .replace("ELSE", "else")
      let bianliang;
      try {
        const newarr = newstr.match(/.*\n/g); //此处 \n 或 \r
        let newarr1 = newstr;
        if (newarr) {
          newarr1 = newarr.map((str, index) => {

            bianliang = str.match(/\s*([A-Z0-9\u4e00-\u9fa5]+)\s*=\s*'?(\S+)'?\s*\n/)
            if (index == 1 && bianliang) {
              window[bianliang[1]] = null;
            }
            if (bianliang && !bianliang[2].endsWith("'")) {
              str = ` ${bianliang[1]}='${bianliang[2]}'\n`
            }
            if (str.startsWith("if") || str.startsWith("else")) {
              return str.replace(/[\n\r\s]+$/, "")
            } else {
              return str.replace(/[\n\r\s]+$/, ";")
            }
          })//.join("");
					let evalstr=""
					for(let i=0;i<newarr1.length;i++){
						if(newarr1[i].startsWith("if")){
							let d=eval(evalstr);
							if(d !== undefined){
								return d
							}else{
								evalstr=newarr1[i]
							}
						}else if(newarr1[i].startsWith("else")){
							let d=eval(newarr1[i+1]);
							return d
						}else{
							evalstr+=newarr1[i];
						}

						if( i == newarr1.length-1){
							let d=eval(evalstr);
							if(d !== undefined){
								return d
							}
						}
					}
					return undefined;
        } else {
          bianliang = newstr.match(/(\s+)?([A-Z0-9\u4e00-\u9fa5]+)\s*=\s*'?([a-zA-Z0-9,\u4e00-\u9fa5]+).*/)
          if (bianliang) {
            bianliang[1] = bianliang[2];
            window[bianliang[1]] = null;
          }
        }
        let evalResule = eval(newarr1)
        return evalResule;
      } catch (error) {
        this.$message.error(`${rowName}的特征算法解析错误`);
        return null;
      }
    },
    priceSort(txsfArr, optional_processing, pro_code, production_model) {
      const pro_selection2Arr = []//production_model.split("-");
      if (pro_code.length != 1) {
        return null;
      }
      pro_code = pro_code[0].pro_code;
      if (production_model.startsWith(`${pro_code}`)) {
        production_model = production_model.replace(`${pro_code}`, "")
      } else {
        return null;
      }
      for (let i = 0; i < txsfArr.length; i++) {
        if (txsfArr[i].var_type == 1) {
          if (txsfArr[i].param_type == 1) {
            let product_param = txsfArr[i].product_param.replace(/[\u4e00-\u9fa5]/g, "")
            let specific_param = txsfArr[i].specific_param.split(",").filter((j) => j.trim())
            let notpipei = true;
            for (let j = 0; j < specific_param.length; j++) {
              if (production_model.startsWith(`-${product_param}${specific_param[j]}-`)) {
                pro_selection2Arr.push(`${txsfArr[i].product_param}${specific_param[j]}`)
                production_model = production_model.replace(`-${product_param}${specific_param[j]}`, "")
                notpipei = false
                break;
              } else if (production_model == `-${product_param}${specific_param[j]}`) {
                pro_selection2Arr.push(`${txsfArr[i].product_param}${specific_param[j]}`)
                production_model = ""
                notpipei = false
                break;
              }
            }
            if (notpipei) {
              pro_selection2Arr.push(`${txsfArr[i].product_param}无`)
            }
          } else if (txsfArr[i].param_type == 2) {
            let product_param = txsfArr[i].product_param.replace(/[\u4e00-\u9fa5]/g, "")
            const reg1 = new RegExp(`^-${product_param}(\\d+(.\\d+)?)-`)
            const reg2 = new RegExp(`^-${product_param}(\\d+(.\\d+)?)$`)
            const match1 = production_model.match(reg1)
            const match2 = production_model.match(reg2)
            if (match1) {
              pro_selection2Arr.push(`${txsfArr[i].product_param}${match1[1]}`)
              production_model = production_model.replace(`-${product_param}${match1[1]}`, "")
            } else
              if (match2) {
                pro_selection2Arr.push(`${txsfArr[i].product_param}${match2[1]}`)
                production_model = ""
              } else {
                return null
              }
          }
        }
      }
      let optionalPrice = 0;
      //pro_selection2Arr.shift()
      //production_model
      if (production_model != "") {
        for (let i = 0; i < optional_processing.length; i++) {
          let op = optional_processing[i];
          if (op.var_type == 3) {
            if (op.param_type == 4) {
              op.specific_param = op.specific_param.split(",")
              op.specific_param.map((opItem) => {
                if (production_model.startsWith(`-${opItem}-`)) {
                  optionalPrice = this.accAdd(optionalPrice, 1);
                  production_model = production_model.replace(`-${opItem}`, "")
                  console.log(production_model)
                } else if (production_model == `-${opItem}`) {
                  optionalPrice = this.accAdd(optionalPrice, 1);
                  production_model = ""
                }
              })
            } else if (op.param_type == 2) {

              if (production_model.startsWith(`-${op.product_param}-`)) {
                let temp_production_model = production_model;
                let flag = true;
                production_model = production_model.replace(`-${op.product_param}`, "")

                op.specific_param = op.specific_param.split(",");
                op.specific_param.map((opItem) => {
                  const regresult = opItem.match(/^(.+)\:\d+(\.\d+)?~\d+(\.\d+)?\/\d+(\.\d+)?$/);
                  if (regresult) {
                    const reg1 = new RegExp(`^-${regresult[1]}(\\d+(.\\d+)?)-`)
                    const reg2 = new RegExp(`^-${regresult[1]}(\\d+(.\\d+)?)$`)
                    const match1 = production_model.match(reg1)
                    const match2 = production_model.match(reg2)
                    if (match1) {
                      flag = false;
                      optionalPrice = this.accAdd(optionalPrice, match1[1]);
                      production_model = production_model.replace(`-${regresult[1]}${match1[1]}`, "")
                    } else
                      if (match2) {
                        flag = false;
                        optionalPrice = this.accAdd(optionalPrice, match2[1]);
                        production_model = ""
                      }
                  }
                })
                if (flag) {
                  production_model = temp_production_model;
                }
              }

            }
          }
          if (production_model == "") {
            break;
          }
        }
      }
      if (production_model != "") {
        console.log("============================价格解析错误=============================", production_model)
        return null
      }

      let noSort = [];
      let needSort = [];
      let noSortArr = [];
      let oneFlag = true;
      let jiage = {}
      txsfArr.map(i => {
        if (i.var_type == 1) {
          noSort.push(i)
          noSortArr.push(i)
        } else if (i.var_type == 2) {
          if (i.quote_params.length == 0) {
            noSort.push(i)
          } else {
            needSort.push(i)
          }
        } else if (i.param_type == 3 && oneFlag) {
          Object.assign(jiage, i);
          oneFlag = false;
        }
      })

      const s = this.dataSort(noSort, needSort)//递归获取排序结果
      s.push(jiage)
      //console.log(s, pro_selection2Arr, optionalPrice)
      let colName = [];
      let colNameVal = [];

      for (let i = 0; i < noSortArr.length; i++) {
        colName.push(noSortArr[i].product_param)
        if (noSortArr[i].var_type == 1) {
          //页面显示的获取页面输入的结果
          let flag = false;
          let val = NaN;
          const reg = new RegExp(`^${noSortArr[i].product_param}(\\d+(\.\\d+)?)$`);
          for (let j = 0; j < pro_selection2Arr.length; j++) {
            if (reg.test(pro_selection2Arr[j])) {
              val = pro_selection2Arr[j].match(reg)[1]
              break;
            }
          }
          colNameVal.push(val);
        }
      }
      for (let i = 0; i < s.length; i++) {
        if (s[i].var_type != 1) {//不显示的数据执行特征算法
          colName.push(s[i].product_param)

          if (s[i].tzsf) {
            let val = this.dealTZSF(s[i].tzsf, colName, colNameVal, s[i].product_param)
            if (i + 1 == s.length) {
              return this.getBit(this.accAdd(val, optionalPrice))
            } else {
              if (val) {
                colNameVal.push(val)
              } else {
                colNameVal.push(NaN)
              }
            }

          } else {
            colNameVal.push(NaN)
          }
        }
      }
      return null;
    },
    //递归获取排序结果
    dataSort(noSort, needSort) {
      for (let i = 0; i < needSort.length; i++) {
        needSort[i].quote_params = needSort[i].quote_params.filter(j => !noSort.some(k => k.product_param == j))//过滤掉noSort的字段
        if (needSort[i].quote_params.length == 0) {
          noSort.push(needSort[i])
          needSort.splice(i, 1);
          i--;
        }
      }
      //递归调用
      if (needSort.length > 0) {
        return this.dataSort(noSort, needSort)
      } else {
        return noSort
      }
    },
    accMul(arg1, arg2) {
      console.log()
      var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
      try { m += s1.split(".")[1].length } catch (e) { }
      try { m += s2.split(".")[1].length } catch (e) { }
      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    },
    //向下取整两位小数点
		getBit(value, bit = 4) {
			let str = value.toString();
			let strIndex = str.indexOf(".");
			if (strIndex == -1) return value
			str = str.substring(0, strIndex + bit)
			let num= Number.parseFloat(str);
			return Math.round((num) * 100) / 100;
		},
    accAdd(arg1, arg2) {
      var r1, r2, m;
      try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
      try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2))
      return (arg1 * m + arg2 * m) / m
    },
    // 返回
    backUpStep() {
      this.showQoute = true
    },
    isNumber(num) {
      return /^[0-9]+.?[0-9]*$/.test(num)
    },
    // 提交
    submit() {
      let qouteData = this.qouteData;
      let production = []
      qouteData.forEach(item => {
        let { production_model, quantity, remark, price, tax_price, total_price } = item
        let obj = {}
        obj.production_model = production_model
        obj.quantity = quantity
        obj.remark = remark
        if (this.isNumber(price)) {
          Object.assign(obj, { price, tax_price, total_price })
        }

        production.push(obj)
      })
      console.log(production, qouteData)
      quotation({ production: production }).then(res => {
        this.$router.push('/user/quote')
        console.log(res, ":::000000::______")
      })
    },
    // 详情操作事件：type: 0 修改，，1 删除;rowIndex：行下边;rowData: 行数据
    operation(type, rowIndex, rowData) {
      // 修改逻辑
            if (type === 0) {
        this.showEditor = true
        this.formData = rowData
        this.editorIndex = rowIndex
        console.log(rowData, rowIndex, this.formData, "::::0000")

        return false
      }
      // 删除逻辑
      this.qouteData.splice(rowIndex, 1)
    },
    /**
     * type: cancle/submit
     * data: 表单数据-cancle的时候不返回
    */
    dialogConfim(type, data) {
      if (type === 'submit') {
        console.log(data)
        if (isNaN(data.price)) {
          data = Object.assign({}, data, { price: '' })
        }

        quotationConfirm({ production: [data] }).then((res) => {
          const item = res[0];


          item.goods_discount = [];
          item.is_exist = 2;
          if (item.goods_id == 0) {

            this.qouteData.splice(this.editorIndex, 1, item)
            this.showEditor = false
          } else {

            this.$http.post('/api/goods/selection', {
              goods_id: item.goods_id,
              production_model: item.production_model
            }).then((data) => {
              console.log(data.pro_selection2)

              data.pro_selection2 = data.pro_selection2.filter((item) => item.var_type == 1 || item.var_type == 2 || item.param_type == 3)
              data.pro_code = data.pro_code.filter((item) => {
                return data.pro_selection2.some((j) => j.goods_param_id == item.id)
              })
              data.pro_selection2 = data.pro_selection2.filter((item) => {
                return data.pro_code.some((j) => j.id == item.goods_param_id)
              })
              data.pro_selection2.sort((a, b) => a.sort - b.sort)
              data.optional_processing = data.optional_processing.filter(j => j.var_type == 3 && (j.param_type == 2 || j.param_type == 4))



              const money = this.priceSort(data.pro_selection2, data.optional_processing, data.pro_code, item.production_model)
              this.$http.get(`/api/goods/${item.goods_id}`).then(({ goods_discount }) => {

                item.goods_discount = goods_discount;

                if (money) {
                  const price = money
                  const tax_price = this.getBit(this.accMul(money, 1.13))
                  const total_price = this.accMul(tax_price, item.quantity)
                  let discountPrice = total_price;
                  let flag=true;
                  let price1 = price;
                  let tax_price1 = tax_price;
                  for (let i = 0; i < goods_discount.length; i++) {
                    if (goods_discount[i].min <= item.quantity && goods_discount[i].max >= item.quantity) {
                      price1 = this.getBit(this.accMul(price1, goods_discount[i].discount))
                      tax_price1 = this.getBit(this.accMul(tax_price1, goods_discount[i].discount))
                      discountPrice = this.getBit(this.accMul(total_price, goods_discount[i].discount))
                      flag=false;
                      break;
                    }
                  }
                  if(flag){
                    item.is_exist = 2;
                    this.qouteData.splice(this.editorIndex, 1, Object.assign({}, item, {price1:''}))
                    this.showEditor = false

                  }else{
                    item.is_exist = 1;
                    this.qouteData.splice(this.editorIndex, 1, Object.assign({}, item, { price, tax_price,price1,tax_price1, total_price, discountPrice }))
                    this.showEditor = false
                  }
                } else {
                  this.qouteData.splice(this.editorIndex, 1, Object.assign({}, item, {price1:''}))
                  this.showEditor = false
                }
              })

            })
          }



        }).catch(() => {
          this.dialogBtnLoading = false

        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn1,
.btn2 {
  width: 69.6px;
  height: 39px;
  border: 1px solid #f1d6d6;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #f3e9e9;
}

.btn1:hover {
  color: #D9262C !important;
  border: 1px solid #D9262C;
}

.btn2:hover {
  background-color: #e9555a;
  border: 1px solid #D9262C;

}

.btn2 {
  background-color: #D9262C;
  color: #fff;
}

.qoue-detail-box {
  width: 1200px;
  margin: 20px auto;

  .ptitle {
    height: 18px;
    line-height: 18px;
    margin-top: 45px;
    margin-bottom: 10px;
    padding-left: 10px;

    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
  }

  .btn-box {
    margin: 20px auto
  }
}

.quoteBoxPage {
  .iconfont {
    font-family: "iconfont" !important;
    font-size: 13px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .projectbox {
    .project {
      position: fixed;
      top: 20%;
      left: 50%;
      width: 696px;
      min-height: 300px;
      border: #aaa solid 1px;
      background: #fff;
      z-index: 1001;
      margin-left: -348px;
      box-shadow: 0 0 15px rgb(0 0 0 / 30%);

      .dropzone {
        width: 625px;
        min-height: 150px;
        border: 2px dashed #0087F7;
        background: white;
        margin: 10px auto 40px;
        padding: 20px 20px;
        box-sizing: border-box;

        ::v-deep .el-upload {
          width: 100%;

          .el-upload-dragger {
            border: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .el-upload__text {
              line-height: 1.4;
            }
          }
        }
      }

      .pro-dl {
        margin: 20px 0 10px;

        .f16 {
          font-size: 16px !important;
        }

        .fl {
          float: left !important;
        }
      }

      .protitle {
        width: 100%;
        height: 55px;
        margin: 0 auto 15px;
        background: #f8f8f8;
        border-bottom: #ddd solid 1px;
        font-size: 22px;
        line-height: 55px;
        text-indent: 25px;
        color: #0d92a2;

        a {
          float: right;
          font-size: 22px;
          color: #0d92a2;
          line-height: 50px;
          margin: 0 15px 0 0;
          cursor: pointer;
        }

        .fl {
          float: left !important;
        }
      }
    }
  }



  .shadow {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #111;
    opacity: 0.4;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  a {
    text-decoration: none;
  }

  img {
    vertical-align: text-top;
    border: 0;
  }

  input:focus {
    outline: none;
  }

  .content {
    width: 1200px;
    height: auto;
    margin: 0px auto;
    padding: 10px 0 20px;

    .nexta {
      margin-top: 45px;
      display: block;
      width: 260px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #D9262C;
      border: #D9262C solid 1px;
      cursor: pointer;
      border-radius: 3px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }

    .conbox {
      padding: 10px 0 0px;
      height: 25px;
      text-align: left;
      margin-top: 5px;

      .control-excel {
        background: #FFFFFF;
        border: 1px solid #CACACA;
        border-radius: 3px;
        font-size: 16px;
        font-weight: 200;
        color: #999999;
        width: 148px;
        height: 28px;
        padding: 0px;
        text-align: center;
        line-height: 28px;

        img {
          vertical-align: text-top;
          margin-right: 6px;
        }
      }

      .btn-gray {
        line-height: 30px;
        background: #FFCA0A;
        border-radius: 3px;
        width: 80px;
        height: 30px;
        font-size: 16px;
        font-weight: 300;
        color: #222222;
        margin-left: 18px;
        border: 0px;
        padding: 0px;
        display: inline-block;
        text-align: center;
      }

      select {
        color: #222222;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        border-radius: 3px;
        text-align: center;
        vertical-align: middle;
        width: 58px;
        height: 28px;
        padding: 0px;
        text-indent: 20px;
      }
    }

    .table-box {
      max-height: 737px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
        // width: 2px;
        // background: #C5C5C5;
      }
    }

    #good-info {
      width: 100%;
      height: auto;
      background: #FFFFFF;
      border-radius: 5px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #222222;
      margin-top: 28px;
      border: 0px;

      tr {
        overflow: hidden;
        display: block;
        margin: 10px 0px;
        height: 67px;
        border: 1px solid #CACACA;
        border-radius: 5px;

        input[type="checkbox"] {
          position: relative;
          vertical-align: middle;
          border: 1px solid #C5C5C5;
          border-radius: 4px;
          width: 22px;
          height: 22px;
          background: #FFFFFF;
        }

        td {
          border: 0px;
          border-right: 1px solid #CACACA;
          height: 66px;
          line-height: 66px;
          padding: 0px;
          text-align: center;
          font-size: 16px;

          input[type="text"] {
            height: 60px;
            border: 0px;
            width: 100%;
            text-align: center;
          }
        }

        th {
          background: none;
          border: 0px;
          font-size: 16px;
          height: 100px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #666666;
          padding: 5px 2px;
        }
      }
    }

    .com-info {
      margin-top: 17px;
      width: 1198px;
      height: 80px;
      border: 1px solid #cacaca;
      border-radius: 5px;
      overflow: hidden;

      .com-list {

        span,
        p {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }

        span {
          border-bottom: 1px solid #fff;
          width: 240px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #666666;
          background: #EEEFF3;
          text-align: center;
        }

        p {
          width: 893px;
          padding: 0 30px;
          border-bottom: 1px solid #EEEFF3;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #222222;
        }
      }
    }

    .ptitle {
      height: 18px;
      line-height: 18px;
      margin-top: 45px;
      margin-bottom: 10px;
      padding-left: 10px;
      border-left: 4px solid #3385FF;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #222222;
    }
  }
}

::v-deep {
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #D9262C
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #D9262C;
    border-color: #D9262C;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #D9262C;
  }

  .el-checkbox__input :hover {
    border-color: #D9262C;
  }

  .el-button el-button--primary:hover {
    color: red;
  }
}
</style>
